import { IconComponent } from '../iconComponentType'

export const LightBulb: IconComponent = (props) => (
  <svg { ...props } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.02 28.251">
    <g transform="translate(520.775 -23.413)">
      <path d="M-388.672,118.191c-3.738,2.253-4.52,6.776-2.975,10.626.369.92.962,1.722,1.417,2.6,1.326,2.553.437,2.326.448,3.3a27.76,27.76,0,0,0,.419,3.771,1.3,1.3,0,0,0,1.34,1.154c9.184,0,9.337.568,9.836-.522.295-.5.189-.842.189-3.875,0-1.452-1.49-.184,1.252-3.915a14.292,14.292,0,0,0,1.845-2.964,8.191,8.191,0,0,0-.453-7.2C-378.018,116.5-384.387,115.61-388.672,118.191Zm5.83,19.836c-1.99-.082-2.156.005-4.148,0-.042-.307-.841-2.859-.392-3.083,2.033-.038,5.544-.541,7.568-.337l.224,3.252c.336.337-1.184.233-1.233.168Zm5.045-9.082a9.907,9.907,0,0,0-2.635,4.385c-.485-.046-1.571-.086-2.056-.12,0-.58-1.043-3.088.231-3.954.017-.065-.033.042-.027-.018l1.514-1.458c.984-.983-.171-2.142-1.155-1.156l-1.053.828c-.218-.367-.454-1.172-.723-1.517-.67-.862-2.362.155-1.324,1.493a8.105,8.105,0,0,1,.714,1.526,11.487,11.487,0,0,0-.257,4.158c-.738-.021-2.263-.032-3-.019A9.977,9.977,0,0,0-389.1,129c-.35-.569-3.078-6.9,2.207-10.091,2.954-1.78,6.895-.049,9.209,2.186A6.414,6.414,0,0,1-377.8,128.945Z" transform="translate(-121.184 -88.121)"/>
      <path d="M-516.174,228.9c-1.22-.009-2.432-.615-3.646-.725-1.278-.116-1.267,1.874,0,1.989,1.214.11,2.426.213,3.646.221C-514.892,230.393-514.893,228.908-516.174,228.9Z" transform="translate(0 -193.278)"/>
      <path d="M-394.66,29.961a30.875,30.875,0,0,0-2.255-3.074c-.839-.974-1.678.44-.846,1.407.721.836.772,1.754,1.383,2.671C-395.669,32.026-393.946,31.031-394.66,29.961Z" transform="translate(-115.794 -2.97)"/>
      <path d="M-133.276,27.442c.526-.841,1.052-1.681,1.547-2.541a1,1,0,0,0-1.717-1c-.5.86-.348,1.7-.874,2.541C-135,27.53-133.953,28.527-133.276,27.442Z" transform="translate(-364.581 0)"/>
      <path d="M-44.742,201.857a21.3,21.3,0,0,0,3.663-1.426c1.15-.568.142-1.554-1-.989-1.029.507-2.084.187-3.188.5A1,1,0,0,0-44.742,201.857Z" transform="translate(-448.184 -166.001)"/>
    </g>
  </svg>
)
