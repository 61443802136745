import { IconComponent } from '../iconComponentType'

export const UpArrowFooterCta: IconComponent = (props) => (
  <svg { ...props } viewBox="0 0 27.3 12.8">
    <g id="Group_2979" transform="matrix(0.966, 0.259, -0.259, 0.966, 3.546, 0.517)">
      <path id="Path_5152" d="M-1.9,0.9c0-0.3,0.3-0.6,0.6-0.6c0,0,0.1,0,0.1,0c1.9,0.2,3.7,0.5,5.5,1
        c0.2,0.1,0.3,0.3,0.2,0.5C4.5,2.1,4.3,2.2,4.1,2.2C2.5,1.8,0.9,1.8-0.7,1.6c0,0.6,0,1.2,0,1.8c0.1,0.8,0.1,1.7,0.1,2.5
        c0,0.4-0.6,0.7-0.8,0.2C-2.1,4.6-1.9,2.6-1.9,0.9z"/>
      <path id="Path_5153" d="M22.9-3.8c-0.2,0.6-0.5,1.3-0.8,1.9s-0.6,1.2-0.9,1.8c-0.6,1.2-1.4,2.3-2.2,3.4
        c-0.8,1-1.8,2-2.9,2.7c-1.1,0.8-2.3,1.3-3.5,1.5c-0.1,0-0.2,0-0.2,0l-0.2,0c-0.1,0-0.2,0-0.2,0l-0.2,0l-0.1,0h-0.1h-0.2
        c-0.2,0-0.3,0-0.5,0c-0.6-0.1-1.3-0.2-1.9-0.4L8.4,7.1C8.2,7,8.1,7,7.9,6.9L7.7,6.8L7.5,6.8C7.3,6.7,7.2,6.6,7,6.6S6.7,6.4,6.6,6.4
        S6.3,6.2,6.1,6.2L5.9,6C5.8,6,5.8,6,5.7,5.9L5.3,5.7C5.1,5.6,5,5.5,4.8,5.5L4.4,5.2C4.1,5,3.8,4.9,3.6,4.7L3.1,4.4L2.9,4.3L2.7,4.1
        L2.3,3.8c-0.1,0-0.1-0.1-0.2-0.1L1.9,3.5C0.8,2.8-0.3,1.9-1.4,1.1l0,0c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0.8,1.1,1.7,2.2,2.7,3.2
        c0.5,0.5,1,1,1.6,1.4C2.9,5.9,3,6,3.2,6.1l0.4,0.3L4,6.7C4.2,6.8,4.3,6.9,4.5,7l0.5,0.3c0.1,0,0.2,0.1,0.2,0.1l0.2,0.1
        c0.3,0.2,0.6,0.3,1,0.5C7,8.3,7.7,8.6,8.5,8.7C9.2,8.9,9.9,9,10.6,9c0.2,0,0.4,0,0.6,0l0.3,0l0.1,0l0.1,0l0.3,0c0.1,0,0.2,0,0.3,0
        l0.3,0c0.1,0,0.2,0,0.3-0.1c0.7-0.2,1.4-0.4,2.1-0.7c0.7-0.3,1.3-0.7,1.8-1.1C17.9,6,18.8,5,19.6,3.8c0.8-1.1,1.5-2.4,2-3.6
        c0.6-1.3,1-2.6,1.4-3.9C23-3.8,23-3.8,22.9-3.8C22.9-3.9,22.9-3.8,22.9-3.8L22.9-3.8z"/>
    </g>
  </svg>
)
