import { IconComponent } from '../iconComponentType'

export const Digital: IconComponent = (props) => (
  <svg
    {...props}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x={0}
    y={0}
    viewBox="0 0 31.6 29.8"
    xmlSpace="preserve"
    {...props}
  >
    <g id="Group_3386">
      <defs>
        <path id="SVGID_1_" d="M0 0h31.6v29.8H0z" />
      </defs>
      <clipPath id="SVGID_00000036938948822689799370000014484833329649572796_">
        <use
          xlinkHref="#SVGID_1_"
          style={{
            overflow: 'visible',
          }}
        />
      </clipPath>
      <g
        id="Group_3385"
        style={{
          clipPath:
            'url(#SVGID_00000036938948822689799370000014484833329649572796_)',
        }}
      >
        <path
          id="Path_6916"
          d="M23.1 23.4h-.3c-.6 0-1.1.4-1.1 1-.1.7-.2 1.4-.4 2.1v.2c-1.1.2-2.3.4-3.5.6-.7.1-1.3.2-2 .3-.6-.7-1.1-1.4-1.7-2.2-.1-.1-.2-.2-.3-.2-.3-.2-.6-.2-.9-.1-.4.3-.9.6-1.3.8-.3.2-.7.4-1 .7-1.1-.3-2.2-.8-3.2-1.3 0-.5.1-.9.1-1.4 0-.6.1-1.3.1-1.9 0-.1 0-.3-.1-.4-.1-.4-.4-.7-.8-.7L4 20.3c-.2-.6-.4-1.3-.6-1.9-.4-1.1-.7-2.2-1-3.3l.1-.1c.6-.4 1.1-.9 1.7-1.3.4-.3.6-.8.5-1.2 0-.2 0-.4-.1-.6-.6-.8-1.1-1.5-1.7-2.2.7-1.3 1.2-2.4 1.7-3.5 1.1.1 2 .2 3 .2.6.3 1.3.1 1.6-.5.1-.1.1-.2.1-.4.1-.8.2-1.5.4-2.2v-.2c1.1-.2 2.3-.4 3.5-.6.7-.1 1.3-.2 2-.3.6.7 1.1 1.4 1.7 2.2.2.2.5.4.8.4.3 0 .6-.1.8-.3l.1-.1c.4-.4.9-.8 1.3-1.2l.6-.6.2-.3-.6-.9h-.6c-.1 0-.3.1-.4.2-.3.2-.5.5-.8.8L16.8.6c0-.1-.1-.1-.1-.1-.2-.4-.7-.5-1.1-.4h-.3c-.8.1-1.6.3-2.4.4-1.3.2-2.7.4-4.1.7-.6 0-1 .3-1 .8 0 .1-.1.2-.1.3l-.1.3c-.1.7-.3 1.3-.4 2-1 0-2-.1-3.1-.2-.4-.1-.8.2-.8.6C2.7 6.5 2 8 1.2 9.3c-.2.3-.1.7.1 1 0 .1.1.1.1.1.4.5.8 1.1 1.3 1.7-.6.4-1.2.9-1.7 1.3l-.3.2c-.1.1-.2.1-.2.2-.4.3-.6.8-.5 1.2.4 1.3.8 2.7 1.2 3.9.3.8.5 1.5.7 2.3 0 .1.1.2.1.3.2.5.6.8 1 .8.1 0 .1 0 .2.1.9.2 1.7.3 2.6.5 0 .3 0 .6-.1.9 0 .7-.1 1.3-.1 2 0 .3.1.6.4.8h.1c1.4.7 2.8 1.3 4.3 1.7h.2c.2 0 .4 0 .5-.1.5-.3.9-.6 1.4-.9l.2-.1c.6.7 1.1 1.3 1.6 2 0 .1.1.1.1.1.2.3.5.4.8.4h.6c.8-.1 1.6-.3 2.4-.4 1.3-.2 2.7-.4 4.1-.7.4-.1.8-.4.8-.9 0-.1.1-.2.1-.3l.1-.4c.1-.6.3-1.3.4-1.9l-.2-1.5c-.2.1-.3-.1-.4-.2"
        />
        <path
          id="Path_6917"
          d="M31.5 12c-.4-1.4-1.7-2.3-3.1-2-.2 0-.4 0-.6.1-.9.2-1.7.7-2.1 1.5-.1.2-.2.4-.3.7-1.5.1-3 .2-4.4.5-.4-1-1.2-1.8-2.1-2.3.4-1.1 1-2.1 1.6-3 .5-.4 1-.6 1.5-.6.4 1.2 1.6 2 2.9 1.9.3 0 .7 0 1-.1h.1c.1 0 .2-.1.3-.1.1 0 .2-.1.4-.2 0 0 .1 0 .1-.1 1-.6 1.6-1.7 1.4-2.9-.3-1.4-1.6-2.3-3-2.2h-.6c-.9.1-1.7.7-2.2 1.4-.3.4-.4.6-.4.8-.6 0-1.2 0-1.8.1-.7.1-2.1 3-2.6 4.2-.6-.2-1.3-.2-1.9-.2-.6 0-1.3.1-1.9.3-.1 0-.2 0-.2.1-.3 0-.4.1-.6.1-.2.1-.5.2-.7.3-.1 0-.1.1-.2.1-2 1.1-3 3.3-2.7 5.5.4 2.9 3.2 4.2 5.8 4.2.4 0 .7 0 1.1-.1 1.3-.2 2.5-.8 3.4-1.7.6 1.3 1.4 2.7 2 2.8.5.1 1.1.1 1.7.1.3.9 1 1.6 1.9 1.9.2.1.4.1.5.2.9.2 1.8.1 2.6-.4.6-.4 1-1.1 1.1-1.8.1-1.3-.6-2.6-1.9-3-.3-.1-.6-.2-1-.3h-.9c-1.1.1-2 .9-2.3 1.9-.5 0-.9-.2-1.3-.4-.6-.7-1-1.5-1.4-2.4.5-.8.7-1.7.7-2.6 1.2-.3 3.2-.6 4-.8.5 1.2 1.7 1.9 3 1.8.3 0 .7-.1 1-.2h.1c.1 0 .2-.1.3-.1.1-.1.2-.1.4-.2 0 0 .1 0 .1-.1 1-.3 1.5-1.5 1.2-2.7m-7.6-7c.3-.2.6-.3 1-.3.7-.1 1.4.4 1.6 1.1.1.3 0 .6-.1.8-.4.5-.9.8-1.5.9h-.1c-.6.1-1.2-.3-1.4-.9-.3-.6-.1-1.3.5-1.6m2.2 14.4c.3 0 .7 0 1 .1.7.2 1.1 1 1 1.7 0 .3-.2.6-.5.7-.6.3-1.2.3-1.8.2 0 0-.1 0-.1-.1-.6-.2-1-.8-.9-1.4.1-.6.6-1.1 1.3-1.2m-10.9-1h-.4c-1.5.1-2.9-.9-3.4-2.4-.4-1.6.3-3.3 1.8-4 .7-.4 1.6-.6 2.4-.6 1.8-.1 3.5 1.1 3.9 2.9.2.7 0 1.5-.4 2.1-1 1.1-2.4 1.9-3.9 2m14.4-4.5c-.6.3-1.2.4-1.8.2 0 0-.1 0-.1-.1-.6-.2-1-.8-.9-1.4.1-.7.6-1.2 1.3-1.3.3 0 .7 0 1 .1.7.2 1.1 1 1 1.7-.1.4-.3.6-.5.8"
        />
      </g>
    </g>
  </svg>
)
