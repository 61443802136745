const detectWebPSupport = () => {
  let support = true // Default to true. If you don't use a browser that supports webp you're a second class citizen and can wait for page hydration

  if (typeof document !== 'undefined') {
    const elem = document?.createElement('canvas')

    support = elem.getContext && elem.getContext('2d') ? elem.toDataURL('image/webp').indexOf('data:image/webp') == 0 : false
  }

  return support
}

export const IMAGE_FILTER = detectWebPSupport() ? '/m/filters:format(webp)' : ''
