import { IconComponent } from '../iconComponentType'

export const FilterIcon: IconComponent = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.201 62.106">
    <g id="Group_3101" data-name="Group 3101" transform="translate(-417.164 -1227.323)">
      <path id="Path_6489" data-name="Path 6489" d="M37.456,39.669a22.865,22.865,0,0,0,3.66-4.322c3-5.69,4.259-11.769,2.771-17.038C41.515,9.907,35.953,1.866,26.907.325A25.682,25.682,0,0,0,7.318,5.393C1.583,9.633-.873,17.414.276,25.6A23.79,23.79,0,0,0,5.534,35.971a30.493,30.493,0,0,0,3.074,3.1C16.815,44.707,28.582,45.959,37.456,39.669Z" transform="translate(417.164 1255.336) rotate(-39)"/>
      <g id="Group_2584" data-name="Group 2584" transform="translate(447.376 1263.02) rotate(45)">
        <path id="Path_6478" data-name="Path 6478" d="M12.782,4.657c1.965-2.085,1.992-1.624,3.931-3.716.4-.44-.114-1.168-.573-.873h0c-2.321,1.484-2.357,1.471-4.6,3.117a23.607,23.607,0,0,0-3.8,3.363C3.7,10.426,0,15.508,0,17.057S10.924,6.634,12.782,4.657Z" transform="translate(-7.46 -8.868)" fill="#fff"/>
      </g>
      <g id="Group_2583" data-name="Group 2583" transform="translate(447.376 1253.02) rotate(45)">
        <path id="Path_6478-2" data-name="Path 6478" d="M12.782,4.657c1.965-2.085,1.992-1.624,3.931-3.716.4-.44-.114-1.168-.573-.873h0c-2.321,1.484-2.357,1.471-4.6,3.117a23.607,23.607,0,0,0-3.8,3.363C3.7,10.426,0,15.508,0,17.057S10.924,6.634,12.782,4.657Z" transform="translate(-7.46 -8.868)" fill="#fff"/>
      </g>
      <path id="Path_6491" data-name="Path 6491" d="M3.046,6.511a4.269,4.269,0,0,0,.3-.709A7.389,7.389,0,0,0,3.569,3C3.376,1.626,2.924.306,2.188.053,1.548-.157,1,.284.6.885A5.748,5.748,0,0,0,.022,4.2,6.05,6.05,0,0,0,.45,5.9a5,5,0,0,0,.25.509C1.367,7.337,2.324,7.543,3.046,6.511Z" transform="matrix(1, -0.017, 0.017, 1, 441.117, 1259.826)" fill="#fff"/>
      <path id="Path_6490" data-name="Path 6490" d="M3.046,6.511a4.269,4.269,0,0,0,.3-.709A7.389,7.389,0,0,0,3.569,3C3.376,1.626,2.924.306,2.188.053,1.548-.157,1,.284.6.885A5.748,5.748,0,0,0,.022,4.2,6.05,6.05,0,0,0,.45,5.9a5,5,0,0,0,.25.509C1.367,7.337,2.324,7.543,3.046,6.511Z" transform="matrix(1, -0.017, 0.017, 1, 450.117, 1248.826)" fill="#fff"/>
    </g>
  </svg>
)
