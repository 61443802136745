import { IconComponent } from '../iconComponentType'

export const Pizza: IconComponent = (props) => (
  <svg { ...props } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.516 21.671">
    <g transform="matrix(0.656, -0.755, 0.755, 0.656, -220.718, 323.54)">
      <path d="M379.816-23.86c3.059-4.645,5.873-8.929,8.7-13.2.619-.936,1.334-.85,1.83.171q2.694,5.545,5.4,11.085a1.856,1.856,0,0,1-1.5,2.595c-3.859.023-7.719.031-11.578-.03A16.475,16.475,0,0,1,379.816-23.86ZM389-35.647c-.564.852-.93,1.456-1.345,2.025-1.752,2.4-3.54,4.779-5.27,7.2-.985,1.377-.815,1.886.824,2,2.755.193,5.526.3,8.287.248,3.14-.057,3.416-.642,1.686-3.322A22.517,22.517,0,0,1,390-33.744,10.918,10.918,0,0,0,389-35.647Z" transform="translate(0 0)"/>
      <path d="M403.587-7.025A2.736,2.736,0,0,1,406.034-10a2.628,2.628,0,0,1,2.492,2.78A2.752,2.752,0,0,1,405.763-4.8,2.042,2.042,0,0,1,403.587-7.025Zm3.674-.827c-.217-.2-.573-.783-.92-.778-.96.014-1.355.781-1.278,1.643.033.372.482,1,.744,1.006C406.739-5.978,407.161-6.644,407.261-7.852Z" transform="translate(-17.773 -20.718)"/>
    </g>
  </svg>
)
