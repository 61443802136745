import { IconComponent } from '../iconComponentType'

export const DownloadArrow: IconComponent = (props) => (
  <svg version="1.1" id="Layer_1"
    x="0px" y="0px"
    viewBox="0 0 15.3 19.4" { ...props } xmlSpace="preserve">
    <g>
      <path className="st0" d="M14.7,17.6l-0.6-0.1c-1.1-0.3-1.3-0.3-2.8-0.6c-0.7-0.2-1.4-0.2-2.2-0.2c-0.3,0-0.6,0-1,0
		c-3.4-0.2-4.5-0.2-8.1,1l0.2,0.5c0,0,0,0,0,0l0.1,0.8c0,0,0,0,0,0l0,0.5c2.4-0.1,4-0.2,4.9-0.2c0.3,0,0.5,0,0.6-0.1
		c0.1,0,0.1,0,0.2,0c0.1,0,0.5,0,1.2,0c1,0,1.6,0,2.3,0c0.5,0,1,0,1.7,0c0.5,0,1.1,0,1.6,0.1c0.6,0.1,1.2,0.1,1.7,0.1
		c0.4,0,0.8-0.3,0.8-0.7C15.3,18.1,15.1,17.7,14.7,17.6z"/>
      <path className="st0" d="M5.8,12.5c0.3,0.3,0.6,0.6,0.9,0.9l0,0c0.5,0.5,1.4,0.5,1.9,0l0,0c0.1-0.1,0.1-0.1,0.2-0.2
		c0.6-0.8,1.2-1.5,1.9-2.1c1-1,1.9-2.1,2.6-3.3c0.3-0.5,0.1-1.2-0.4-1.5c-0.5-0.3-1.2-0.1-1.5,0.4c-0.3,0.5-0.6,0.9-1,1.3
		C9.9,8.3,9.6,8.6,9.2,9C9,9.1,8.9,9.2,8.7,9.4c0.1-0.3,0.1-0.6,0.2-1.1c0.2-0.7,0.3-1.4,0.3-2.2c0-0.3,0-0.6,0.1-1
		c0-0.4,0-0.7,0.1-1c0.1-1.3,0.1-1.4-0.2-2.2C8.8,1.3,8.6,0.7,8.4,0L7.9,0.2L7.1,0.2l-0.5,0c0,1.6,0.1,2.7,0.1,3.4
		c0,0.3,0,0.5,0,0.7c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.2c0,0.6,0,1.1,0,1.6c0,0.5,0,1,0,1.7c0,0.5,0,1-0.1,1.5
		C6.5,9.5,6.3,9.3,6.2,9.1l0,0C5.9,8.8,5.7,8.5,5.4,8.2C4.9,7.6,4.4,7,3.8,6.5C3.3,6.1,2.6,6.2,2.2,6.6C1.9,6.9,1.8,7.3,2,7.7
		C2.9,9.6,4.2,11.2,5.8,12.5z"/>
    </g>
  </svg>

)
