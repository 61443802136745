import { IconComponent } from '../iconComponentType'

export const Hand: IconComponent = (props) => (
  <svg { ...props } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.975 33.022">
    <g transform="translate(784.844 -683.695)">
      <path d="M-734.937,772.409a3.272,3.272,0,0,0-1.112.194,3.526,3.526,0,0,0-3.3-2.02,3.269,3.269,0,0,0-1.1.191,2.987,2.987,0,0,0-2.919-2.047,3.278,3.278,0,0,0-1.006.157l-.079-3.52c0-1.511-1.725-2.117-3.388-2.117a2.89,2.89,0,0,0-3.014,2.74c0,.064.366,6.465.363,9.539-1.254.857-2.649,1.7-2.649,3.142a9.775,9.775,0,0,0,3.376,6.964c.468.428.749.687.918.843v3.38c0,.5.45,1.46,1.006,1.46l12.905-.546a1.277,1.277,0,0,0,1.182-1.074l-.176-3.373c1.317-2.466,1.362-4.526,1.362-7.266l.427-3.647A2.916,2.916,0,0,0-734.937,772.409Zm1,6.455c0,2.531-1.036,4.572-2.283,6.836a.849.849,0,0,0-.106.409l.6,3.111-11.024.155.128-3.1a1.183,1.183,0,0,0-.4-1.134c-.219-.2-.972-.481-1.312-.792a7.485,7.485,0,0,1-2.521-5.555,2.718,2.718,0,0,1,1.51-2.372c.31-.163.686-.464.686-.789,0-2.87-.12-9.734-.12-9.8,0-.5.389-.754.941-.754s1.323.232,1.323.735l.133,9.311a.963.963,0,0,0,1.006.913c.556,0,.763-2.331.763-2.835l.243-1.732a.964.964,0,0,1,1.006-.913c.555,0,1.486.434,1.486.938,0,.451-.214,3.53-.214,3.53,0,.5.184,1.041.74,1.041a.963.963,0,0,0,1.006-.913l-.207-2.145a1.713,1.713,0,0,1,1.282-1.319c.519-.045,1.082.355,1.41,1.28v1.784c0,.5.471.776,1.026.776s.9.128.9-.377a.964.964,0,0,1,1.006-.913c.555,0,1.343.515,1.343,1.018Z" transform="translate(-29.728 -74.597)"/>
      <path d="M-784.838,689.719a.975.975,0,0,0,.861,1.077.975.975,0,0,0,1.077-.861c.3-2.672,2.935-4.179,5.608-3.882s5.06,1.608,4.763,4.28c-.06.535.081,1.745.616,1.8a.975.975,0,0,0,1.077-.861,6.834,6.834,0,0,0-6.03-7.539C-780.607,683.322-784.422,685.978-784.838,689.719Z" transform="translate(0 0)"/>
    </g>
  </svg>
)
