import { IconComponent } from '../iconComponentType'

export const ListItemIcon: IconComponent = (props) => (
  <svg { ...props } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.954 6.928">
    <path d="M3678.473,2496.879a3.63,3.63,0,0,0-2.083-3.285,4.045,4.045,0,0,0-3.177.2,3.3,3.3,0,0,0-1.692,2.912,3.754,3.754,0,0,0,.5,1.759,4.755,4.755,0,0,0,.385.571,4.01,4.01,0,0,0,4.436.954,3.577,3.577,0,0,0,.695-.559A3.7,3.7,0,0,0,3678.473,2496.879Z" transform="translate(-3671.519 -2493.36)" fill="#4a4a4a"/>
  </svg>
)



