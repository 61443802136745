import { IconComponent } from '../iconComponentType'

export const Eyeball: IconComponent = (props) => (
  <svg { ...props } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.812 22.698">
    <g transform="translate(0 0)">
      <path d="M-86.472,184.205c0,.521.2,1.165-.038,1.539-.6.954-1.426,1.761-2.075,2.687-.179.255-.054.724-.068,1.094.328-.072.738-.045.966-.235.452-.377.745-.953,1.212-1.3a8.618,8.618,0,0,1,1.611-.757c-.107.619-.212,1.239-.324,1.857a.3.3,0,0,1-.1.13c-3.265,3.28-1.89,7.22-1.481,10.986.071.658.839,1.223,1.219,1.869a14.8,14.8,0,0,1,.7,1.571l-.547.4c-.625-.582-1.219-1.2-1.888-1.729a7.114,7.114,0,0,0-1.343-.668,7.773,7.773,0,0,0,.376,1.49c.295.632.771,1.18,1.057,1.815.12.265-.05.66-.087,1a6.044,6.044,0,0,1-1.021-.206.973.973,0,0,1-.447-.457c-1.1-2.129-3.032-2.393-5.122-2.32a4.91,4.91,0,0,1-.811.012c-2.474-.332-4.467.159-5.692,2.68-.307.632-1.494.836-2.276,1.237a20.823,20.823,0,0,1,1-2.311c.317-.553.934-.94,1.223-1.5a4.337,4.337,0,0,0,.174-1.515,6.833,6.833,0,0,0-1.63.389c-.35.177-.5.708-.834.972a11.1,11.1,0,0,1-1.4.835l-.544-.462a17.155,17.155,0,0,1,1.721-2.606,2.662,2.662,0,0,0,.748-2.433,17.6,17.6,0,0,1,.185-4.969,2.279,2.279,0,0,0-.812-2.514c-.9-.766-2.058-1.526-1.45-3.035,1.725-.388,2.122,2.371,4.329,1.8a32.643,32.643,0,0,1-2.511-2.62c-.255-.332-.129-.955-.177-1.445.442.073,1.1-.015,1.292.244,1.7,2.259,3.692,2.479,6.246,1.449a5.319,5.319,0,0,1,3.435.427,1.663,1.663,0,0,0,2.138-.436c.851-.972,1.667-1.975,2.5-2.965Zm-1.962,11.834c.033-4.2-2.381-7.444-5.59-7.509-3.557-.072-6.529,3.194-6.594,7.245-.058,3.663,2.254,6.329,5.5,6.339C-91,202.126-88.464,199.826-88.434,196.039Z" transform="translate(104.653 -184.194)"/>
      <path d="M-79.218,210.12c.875.2,2.287-.385,2.58,1.3.357,2.051-.435,4.12-1.886,4.465a32.466,32.466,0,0,1-4.229.468,28.674,28.674,0,0,1-.189-4.1C-82.776,210.423-82.146,210.13-79.218,210.12Zm.312,1.7c-2.283.015-2.78.379-2.731,1.867.03.9.477,1.055,1.275.922C-78.876,214.363-78.556,213.828-78.905,211.82Z" transform="translate(90.023 -201.691)"/>
    </g>
  </svg>
)
